@import 'src/theme/constants';
@import 'src/theme/mixins';

.talentcard-list-item {
    position: relative;
    display: flex;
    margin-bottom: $jmt-spacing * 1.5;
    width: 100%;
    cursor: pointer;

    &__avatar {
        width: 80px!important;
        height: 80px!important;
        object-fit: cover;
        display: block;

        &--no-img {
            opacity: 0.5;
        }

        &-wrapper {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(90deg, transparent 0%, transparent 90%, var(--ion-color-light) 100%);
            }
        }
    }

    &__caption-wrapper {
        z-index: 1;
    }

    &::part(native) {
        display: flex;
    }

    &__talent-id {
        position: absolute;
        right: -1.4 * $jmt-spacing;
        width: $jmt-spacing * 12;
        height: $jmt-spacing * 12;
        opacity: 0.4;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ion-card-content {
        width: 100%;
    }

    &__grid {
        padding: 0 $jmt-spacing * 2;
    }

    ion-grid {
        display: flex;
    }

    ion-row {
        width: 100%;
    }

    &__menu {
        flex-grow: 0;
    }
}
