@import 'src/theme/constants';

.jmt-button {

    &__label {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }

    &--has-content {
        .jmt-button__loading-spinner,
        ion-icon {
            margin-inline-end: $jmt-spacing;
        }
    }

    &--round {
        --border-radius: 50%;
        width: 55px;
        height: 55px;
    }

    &__loading-spinner {
        height: 1em;
        width: 1em;
        margin-inline: -0.3em 0.3em;
        color: currentColor;
    }
}
