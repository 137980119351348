@import "src/theme/constants";

.login-card {
  max-width: $jmt-max-card-width;
  margin: 0 auto;

  &__image {
    padding-top: $jmt-spacing;
    height: $jmt-spacing * 10;
    display: block;
  }

  &__intro-text {
    color: var(--ion-color-primary);
    font-size: $jmt-fontsize-l;
  }

  &__button-bar {
    margin-top: $jmt-spacing * 2;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ion-button:not(:last-child) {
      margin-bottom: $jmt-spacing * 2;
    }
  }
}

.login-slides {
  margin-bottom: $jmt-spacing * 3;
}
