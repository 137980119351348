@import 'src/theme/constants';
@import 'src/theme/_mixins';

.content {
    --padding-top: #{$jmt-spacing};

    &--with-tab-bar {
        --padding-bottom: calc(72px + env(safe-area-inset-bottom)) !important;
    }
}
