@import "src/theme/variables";
@import "src/theme/mixins";

.onboarding-modal {
    --ion-background-color: rgba(var(--ion-background-color-rgb), 0.5);
    backdrop-filter: blur(6px);

    .swiper-slide {
        display: flex;
        flex-direction: column;
        width:  100vw;
        white-space: pre-line;
        text-align: left;

        > * {
            width: 100%;
        }
    }

    &__footer {
        width: 100vw;
        max-width: $jmt-width-desktop;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        padding: $jmt-spacing * 2;

        &__buttons ion-button {
            margin-left: $jmt-spacing * 2;
        }
    }
}
