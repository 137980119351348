@import 'src/theme/constants';
@import "src/theme/transitions";

.talentcard-medium-front {
    position: relative;
    backface-visibility: hidden;
    transform: rotateY(-180deg);
    @include complexTransition(transform);
    transform-style: preserve-3d;
    pointer-events: none;
    will-change: transform;
    width: 100%;

    &__fifa-country {
        width: $jmt-spacing * 5 !important;
        border-radius: 2px;
    }

    &__performance-icon {
        width: $jmt-spacing * 5;
        height: $jmt-spacing * 5;
        margin-top: $jmt-spacing;
    }

    &__profile-link {
        cursor: pointer;

        > * {
            font-size: 19px;
            line-height: 20px;
            text-shadow: 0 0 4px black;
        }
    }

    &--visible {
        transform: rotateY(0);
        pointer-events: all;
    }

    &--preview {
        pointer-events: none;
        filter: grayscale(0.4) opacity(0.3);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    &__attr-title {
        font-size: 11px;
        line-height: 13px;
    }

    &--talent {
        .talentcard-medium-front {
            &__top-container {
                h2, h3 {
                    text-shadow: 0 0 4px black, 0 0 5px var(--ion-color-secondary-shade);
                }
                .good {
                    color: var(--ion-color-success);
                    rotate: 90deg;
                }
                .bad {
                    color: var(--ion-color-danger);
                    rotate: 90deg;
                }
                .equal {
                    color: var(--ion-color-primary);
                }
            }

            &__attr-title {
                color: $jmt-color-stripe-light;
            }
        }
    }

    &--scout {
        .talentcard-medium-front {
            &__top-container {
                h2, h3 {
                    text-shadow: 0 0 4px black, 0 0 5px var(--ion-color-scout-shade);
                }
            }

            &__attr-title {
                color: $jmt-color-stripe-light;
            }
        }
    }

    &--roleModel {
        .talentcard-medium-front {
            &__top-container {
                h2, h3 {
                    text-shadow: 0 0 4px black, 0 0 5px var(--ion-color-roleModel-shade);
                }
            }

            &__attr-title {
                color: var(--ion-color-roleModel);
            }
        }
    }

    &__top-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        h2 {
            font-size: 35px;
            line-height: 38px;
            margin-bottom: calc(#{$jmt-spacing} * 0.5);
            width: $jmt-spacing * 5;
            display: flex;
            justify-content: center;
        }
    }


    &__bottom-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    &__team-container {
        display: flex;
        align-items: center;
        justify-content: center;

        &__meta {
            margin-left: calc(#{$jmt-spacing * 1.5});
            display: flex;
            flex-direction: column;
        }
    }

    &__team {
        font-weight: normal;
    }

    &__attr-wrapper {
        display: flex;

        &__attr {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__skills-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__talentid-wrapper {
        flex-shrink: 0;
        width: $jmt-spacing * 11;
        height: $jmt-spacing * 11;
        cursor: pointer;
    }

    &__ctx-menu {
        position: absolute;
        bottom: -5.5 * $jmt-spacing;
        left: 50%;
        transform: translateX(-50%);
    }

    .talent-top-skills {
        max-width: 64%;
    }

    h4 {
        font-size: 11px!important;
        line-height: 14px!important;
    }

    &__attr-text {
        font-size: 13px!important;
        line-height: 17px!important;
        font-weight: bold;
    }
}
