@import 'src/theme/transitions';

.layer-global {
    animation-name: talentIdZoomIn;
    animation-duration: map-get($jmt-timings, shortest);
    animation-fill-mode: forwards;
    width: 100%;
    height: 100%;
    cursor:pointer;
    user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    -webkit-touch-callout:none;

    .advBg {
        fill: white;
    }

    .touchClick {
        opacity: 0;
    }

    &.touch .touchClick {
        display: inherit;
        fill: transparent;
    }

    .levels circle {
        fill: transparent;
        stroke: white;
        stroke-opacity: .25;
        stroke-width: 1px;
    }

    .levels text {
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-weight: bold;
    }

    .sectors path {
        fill: transparent;
        stroke: white;
        stroke-width: 4px;
    }

    .sectors text {
        font-family: Rubik, sans-serif;
        font-size: 28px;
    }

    .diagRadar path {
        fill: transparent;
    }

    .diagRadar path.radar {
        stroke-width: 4px;
        stroke: white;
        stroke-opacity: .6;
        fill: white;
        fill-opacity: .1;
    }

    .diagRadar text {
        pointer-events: none;
    }

    .diagRadar text.emoLbl {
        font-family: Rubik, sans-serif;
        font-size: 24px;
        fill: var(--ion-text-color-secondary);
    }

    .diagRadar line {
        stroke: white;
        stroke-width: 1px;
        stroke-opacity: .8;
    }

    .diagRadar g line {
        stroke: white;
        stroke-width: 0;
    }

    .diagRadar .emoVal {
        display: none;
    }

    .diagRadar .emoVal text {
        font-family: Rubik, sans-serif;
        font-size: 40px;
        display: none;
        text-anchor: middle;
    }

    .diagRadar .emoVal circle {
        fill: transparent;
    }

    .diagRadar g:hover line {
        stroke-width: 3px;
    }

    .diagRadar g:hover .emoVal circle {
        fill: black;
    }

    .diagRadar g:hover .emoVal text {
        display: inherit;
    }

    .diagRadar.hoverLabels text.emoLbl {
        font-size: 8px;
        fill: transparent;
    }

    .diagRadar.hoverLabels g:hover text.emoLbl {
        fill: var(--ion-text-color-secondary);
        font-size: 24px;
        font-weight: bold;
    }

    .diagPie path {
        stroke: white;
        stroke-width: 4px;
        stroke-opacity: .6;
        fill: white;
        fill-opacity: .1;
    }

    g.animation {
        display: none;
    }

    g.animation path {
        stroke: white;
        stroke-width: 8px;
        stroke-opacity: 1;
        fill: white;
        fill-opacity: .3;
    }

    g.animation path.ghost {
        stroke: white;
        stroke-opacity: .5;
        stroke-width: 4px;
        fill: transparent;
    }

    &.animation .diagRadar path.radar,
    &.animation .diagPie path,
    &.animation .diagRadar .emoVal {
        display: none;
    }

    &.animation g.animation {
        display: inherit;
    }

    &.emoFade .sectors text {
        opacity: .3;
    }
}

.layer-global { pointer-events: none; }
.layer-global.interactive { pointer-events: all; }

.levels { pointer-events: none; }

.layer-global .diagRadar path.l-global { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.layer-global .diagRadar path.l-old { stroke-width: 4px; stroke-opacity: 0.95; fill-opacity: 0.15; }
.layer-global .diagPie.l-global path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.layer-global .diagPie.l-global path { transition: all 0.1s ease-in-out; }

.layer-global:hover .sectors text {
    opacity: 0.1;
}

.sectors text { transition: opacity 0.5s ease 0s; }
.emoFade .sectors text { transition: opacity 0.5s ease 0s; }

.diagRadar.hoverLabels text.emoLbl { transition: font-size 0.05s ease 0s, fill 0.1s ease 0s; }
.diagRadar g:hover line { transition: stroke-width 0.2s ease 0s; }
.diagRadar.hoverLabels g:hover text.emoLbl { transition: font-size 0.1s ease 0s, fill 0.05s ease 0s; }
.diagRadar .emoVal circle { r: 0; transition: all 0.2s ease 0s; }
.diagRadar g:hover .emoVal circle { r: 4px; transition: all 0.1s ease 0s; }
.diagRadar path.radar { transition: all 0.5s ease 0s; }


.layer-global .diagRadar path.l-global { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-global .diagRadar path.l-global { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-global .emoVal.l-global { display: inherit; }
.layer-self .diagRadar path.l-self { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-self .diagRadar path.l-self { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-self .emoVal.l-self { display: inherit; }
.layer-network .diagRadar path.l-network { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-network .diagRadar path.l-network { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-network .emoVal.l-network { display: inherit; }
.layer-followers .diagRadar path.l-followers { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-followers .diagRadar path.l-followers { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-followers .emoVal.l-followers { display: inherit; }
.layer-followed .diagRadar path.l-followed { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-followed .diagRadar path.l-followed { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-followed .emoVal.l-followed { display: inherit; }
.layer-friends .diagRadar path.l-friends { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-friends .diagRadar path.l-friends { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-friends .emoVal.l-friends { display: inherit; }
.layer-network_friends .diagRadar path.l-network_friends { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-network_friends .diagRadar path.l-network_friends { transition: opacity 0.5s ease 0s; opacity: 0; }
/* values not available in thumbs */
.layer-network_friends .emoVal.l-network_friends { display: inherit; }

.radar-transform { @include standardTransition(d); }

.layer-global .diagPie.l-global path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-global .diagPie.l-global path { @include standardTransition(opacity); opacity: 0; }
.layer-self .diagPie.l-self path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-self .diagPie.l-self path { @include standardTransition(opacity); opacity: 0; }
.layer-network .diagPie.l-network path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-network .diagPie.l-network path { @include standardTransition(opacity); opacity: 0; }
.layer-followers .diagPie.l-followers path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-followers .diagPie.l-followers path { @include standardTransition(opacity); opacity: 0; }
.layer-followed .diagPie.l-followed path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-followed .diagPie.l-followed path { @include standardTransition(opacity); opacity: 0; }
.layer-friends .diagPie.l-friends path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-friends .diagPie.l-friends path { @include standardTransition(opacity); opacity: 0; }
.layer-network_friends .diagPie.l-network_friends path { stroke-width: 8px; stroke-opacity: 1; fill-opacity: 0.3; }
.hide-network_friends .diagPie.l-network_friends path { @include standardTransition(opacity); opacity: 0; }

// COLORS

@mixin black-fill {
    fill: black !important;
    fill-opacity: 0.3!important;
}

@mixin path-color-black {
    @include black-fill;
    stroke: black!important;
    stroke-width: 4px!important;
    stroke-opacity: 1;
}

@mixin primary-fill {
    fill: white !important;
    fill-opacity: 0.3!important;
}

@mixin path-color-primary {
    @include primary-fill;
    stroke: white!important;
    stroke-width: 4px!important;
    stroke-opacity: 1;
}

@mixin secondary-fill {
    fill: var(--ion-color-secondary) !important;
    fill-opacity: 0.4!important;
}

@mixin path-color-secondary {
    @include secondary-fill;
    stroke: var(--ion-color-secondary)!important;
    stroke-width: 4px!important;
    stroke-opacity: 1;
}

@mixin tertiary-fill {
    fill: var(--ion-color-tertiary) !important;
    fill-opacity: 0.4!important;
}

@mixin path-color-tertiary {
    @include tertiary-fill;
    stroke: var(--ion-color-tertiary)!important;
    stroke-width: 4px!important;
    stroke-opacity: 1;
}

.layer-global {
    &__background {
        &--secondary {
            .innerStageBg { fill: var(--ion-color-secondary); }
            .stageBg { fill: var(--ion-color-secondary); }
            .sectors text { fill: var(--ion-color-secondary); }
        }

        &--tertiary {
            .innerStageBg { fill: var(--ion-color-tertiary); }
            .stageBg { fill: var(--ion-color-tertiary); }
            .sectors text { fill: var(--ion-color-tertiary); }
        }

        &--black {
            .innerStageBg { fill: white; }
            .diagRadar > line { stroke: $jmt-color-stripe-dark; }
            .stageBg { fill: $jmt-color-stripe-dark; }
            .advBg { fill: $jmt-color-stripe-dark; }
            .sectors text { fill: white; }
            .sectors > path { stroke: $jmt-color-stripe-dark; }
            .sector-0-text { transform: translate(4px, -4px); }
            .sector-1-text { transform: translate(7px, 4px); }
            .sector-2-text { transform: translate(-7px, 4px); }
            .sector-3-text { transform: translate(-4px, -4px); }

            .diagRadar.hoverLabels g:hover text.emoLbl {
                fill: var(--ion-text-color);
            }

            .diagRadar g text.emoLbl {
                fill: var(--ion-text-color);
            }

            .levels {
                circle {
                    stroke: $jmt-color-stripe-dark;
                }

                text {
                    fill: $jmt-color-stripe-light;

                    ~ text {
                        fill: $jmt-color-stripe-dark;
                    }
                }
            }
        }

        &--secondary-transparent {
            .innerStageBg { fill: var(--ion-color-secondary); }
            .diagRadar text.emoLbl { fill: var(--ion-text-color); }
            .stageBg { fill: transparent; stroke-width: 8px; stroke: var(--ion-color-secondary); }
            .advBg { fill: transparent; }
            .sectors text { fill: white; }
            .sector-0-text { transform: translate(4px, -4px); }
            .sector-1-text { transform: translate(7px, 4px); }
            .sector-2-text { transform: translate(-7px, 4px); }
            .sector-3-text { transform: translate(-4px, -4px); }

            .diagRadar.hoverLabels g:hover text.emoLbl {
                fill: var(--ion-text-color);
            }
        }

        &--scout {
            .innerStageBg { fill: var(--ion-color-scout); }
            .stageBg { fill: var(--ion-color-scout); }
            .sectors text { fill: var(--ion-color-scout); }
        }

        &--roleModel {
            .innerStageBg { fill: var(--ion-color-roleModel); }
            .diagRadar > line { stroke: $jmt-color-stripe-dark; }
            .stageBg { fill: $jmt-color-stripe-dark; }
            .advBg { fill: $jmt-color-stripe-dark; }
            .sectors text { fill: var(--ion-color-roleModel); }
            .sectors > path { stroke: $jmt-color-stripe-dark; }
            .sector-0-text { transform: translate(4px, -4px); }
            .sector-1-text { transform: translate(7px, 4px); }
            .sector-2-text { transform: translate(-7px, 4px); }
            .sector-3-text { transform: translate(-4px, -4px); }
            .emoVal text { fill: white; }

            .diagRadar g:hover .emoVal circle {
                fill: white;
            }

            .diagRadar g:hover line {
                stroke: black!important;
            }

            .diagRadar g text.emoLbl {
                fill: white;
            }

            .diagRadar.hoverLabels g text.emoLbl {
                fill: transparent;
            }

            .diagRadar.hoverLabels g:hover text.emoLbl {
                fill: var(--ion-text-color);
            }

            .levels {
                circle {
                    stroke: $jmt-color-stripe-dark;
                }

                text {
                    fill: $jmt-color-stripe-light;

                    ~ text {
                        fill: $jmt-color-stripe-dark;
                    }
                }
            }
        }
    }

    &__color1 {
        &--primary {
            .path {
                &--color1,
                &--combined {
                    @include path-color-primary;
                }
            }
        }

        &--secondary {
            .path {
                &--color1,
                &--combined {
                    @include path-color-secondary;
                }
            }
        }

        &--tertiary {
            .path {
                &--color1,
                &--combined {
                    @include path-color-tertiary;
                }
            }
        }

        &--black {
            .path {
                &--color1,
                &--combined {
                    @include path-color-black;
                }
            }
        }
    }

    &__color2 {
        &--primary {
            .path {
                &--color2 {
                    @include path-color-primary;
                }

                &--overlap {
                    @include path-color-primary;
                }
            }
        }

        &--secondary {
            .path {
                &--color2 {
                    @include path-color-secondary;
                }

                &--overlap {
                    @include path-color-secondary;
                }
            }
        }

        &--tertiary {
            .path {
                &--color2 {
                    @include path-color-tertiary;
                }

                &--overlap {
                    @include path-color-tertiary;
                }
            }
        }

        &--black {
            .path {
                &--color2 {
                    @include path-color-black;
                }

                &--overlap {
                    @include path-color-black;
                }
            }
        }
    }
}

.talent-id-skeleton {
    width: 100% !important;

    &__img-wrapper {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
    }

    img {
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        @include standardTransition(opacity, transform);
        transform: scale(1);
        pointer-events: none;
        padding: 8px;
        //@include standardTransition(opacity);
        filter: blur(4px) grayscale(100%);
        animation-name: loading;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        opacity: 1;
        animation-iteration-count: infinite;
    }
}

@keyframes talentIdZoomIn {
    0% {
        transform: scale(0.45);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loading {
    0% {
        transform: scale(0.55);
    }

    80% {
        transform: scale(0.45);
    }

    90% {
        transform: scale(0.65);
    }

    100% {
        transform: scale(0.55);
    }
}
