@import 'src/theme/constants';

.network-invite-item {
    position: relative;
    display: flex;
    margin-bottom: $jmt-spacing * 1.5;

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &::part(native) {
        display: flex;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ion-card-content {
        width: 100%;
    }

    &__grid {
        padding: $jmt-spacing * 2;
    }

    ion-grid {
        display: flex;
    }

    ion-row {
        width: 100%;
    }

    &__icon {
        flex-grow: 0;
        padding-left: $jmt-spacing;
    }
}
