.modal--alert .modal-wrapper {
    min-height: 200px;
    height: auto;
    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
}

.modal {

    &--fullscreen {
        --height: 100vh;
        --width: 100vw;
    }

    &--safari-fix {
        padding-top: 10vh;
    }

    &--large {
        --height: 100vh;
        --min-height: 100vh;
        --width: 100vw;
        --min-width: 100vw;

        @media only screen and (min-width: 768px) and (min-height: 768px) {
            --height: 80%;
            --min-height: 80%;
            --width: 80%;
            --min-width: 80%;
        }
    }

    &--alert {
        --max-width: 90vw;
        --border-radius: 10px;
        --height: auto;

        ion-header ion-toolbar:first-of-type  {
            padding-top: 0;
        }

        .inner-content {
            padding: 16px 8px;
        }
    }
}
